import React, { useState, useEffect } from "react";
import { Dashboard } from "./Dashboard";
import { VscDebugStart } from "react-icons/vsc";
import { BiSkipNext } from "react-icons/bi";
import { MdOutlineNotes } from "react-icons/md";
import { IoMdRefresh } from "react-icons/io";
import { AiOutlinePlusCircle, AiOutlineStop } from "react-icons/ai";
import {
  AUTH_TOKEN,
  USER_DETAILS,
  BASE_URL,
  TOKEN_KEY,
  AUTH_KEY,
} from "../../utils";
import axios from "axios";
import Swal from "sweetalert2";
import ShiftComponent from "./components/ShiftComponent";
import Header from "./Header";
import { RecallModel } from "../../models/RecallModel";
import { PatientInModel } from "../../models/PatientInModel";
import { SkipRecallModel } from "../../models/SkipRecallModel";
import { BreakModel } from "../../models/BreakModel";
import { StartModel } from "../../models/StartModel";
import { EmergencyModel } from "../../models/EmergencyModel";
import { DescriptionModel } from "../../models/DescriptionModel";
import { PrintModel } from "../../models/PrintModel";
import { useNavigate } from "react-router-dom";
import Head from "./components/Head";
import { Token } from "./Token";
import TokenListDash from "./components/TokenListDash";
import SkipPatientDash from "./components/SkipPatientDash";
import { DashboardTotal } from "./components/DashboardTotal";
import { NextButton } from "./Buttons/NextButton";
import { SkipButton } from "./Buttons/SkipButton";
import RecallButton from "./Buttons/RecallButton";
import DashboardBreak from "./components/DashboardBreak";
import { DonePatientList } from "./components/DonePatientList";
//import Echo from "laravel-echo";
//import Pusher from "pusher-js";

export const DashboardData = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [descriptions, setDescription] = useState([]);
  const [inputValue, setInputValue] = useState(null);
  const [isModel, setIsModel] = useState(false);
  const [isEmergencyModel, setIsEmergencyModel] = useState(false);
  const [isSkipRecallModel, setIsSkipRecallModel] = useState(false);
  const [isRecallModel, setIsRecallModel] = useState(false);
  const [patientInModel, setPatientInModel] = useState(false);
  const [isRecallToken, setIsRecallToken] = useState(null);
  const [tokenType, setTokenType] = useState("pending");
  const [tokenValue, setTokenValue] = useState([]);
  const [skipPatients, setSkipPatients] = useState([]);
  const [finishedPatients, setFinishPatients] = useState([]);
  const [replaceToken, setReplaceToken] = useState(0);
  const [patients, setPatients] = useState([]);
  const [totalFee, setTotalFee] = useState(0);
  const [feeCount, setFeeCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [onlineTokens, setOnlineTokens] = useState(0);
  const [is_break, setIsBreak] = useState(false);
  const [showBreakModal, setShowBreakModal] = useState(false);
  const [isStartButtonVisible, setStartButtonVisible] = useState(false);
  const [shifts, setShift] = useState([]);
  const [startModel, setStartModel] = useState(false);
  const [patientDetails, setPatientDetails] = useState([]);
  const [printModal, setPrintModal] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [shift_id, setShiftId] = useState(
    localStorage.getItem("shift_id") ?? USER_DETAILS.active_shift_id ?? ""
  );

  const [subscriptionEnd, setSubscriptionEnd] = useState(
    localStorage.getItem("subscription_end") || 30
  );
  const [subscriptionDate, setSubscriptionDate] = useState(
    localStorage.getItem("subscription_date") || ""
  );
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [isActive, setIsActive] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    gender: "Male",
    mobile: "",
    email: "",
    shift_id: shift_id,
    checkvaluemsg: "",
  });
  const [permissions, setPermissions] = useState({
    start: true,
    stop: true,
    skip: true,
    recall: true,
    next: true,
    startbreak: true,
    stopbreak: true,
    skipPatient: true,
  });

  const userPer = JSON.parse(localStorage.getItem("role_permissions") || "[]");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const startOpenModel = (is_active) => {
    setFormData({ ...formData, checkvaluemsg: "" });
    setIsActive(is_active);
    setStartModel(!startModel);
  };

  const handleToggleStartButton = async (e) => {
    e.preventDefault();
    const checkValue = e.target.text_check.value.toLowerCase();
    if (checkValue === "start" || checkValue === "stop") {
      setStartModel(!startModel);
      try {
        if (shift_id.length === 0 && isStartButtonVisible === false) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please select your shift.",
          });
          return false;
        }
        setIsLoading(true);
        setFormData({ ...formData, checkvaluemsg: "" });
        const response = await axios.post(
          `${BASE_URL}/doctor/status`,
          { shift_id: formData.shift_id, is_active: isActive },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${AUTH_TOKEN}`,
            },
          }
        );

        if (response.status === 200) {
          allPatient(formData.shift_id);
          setShiftId(formData.shift_id);
          localStorage.setItem("shift_id", formData.shift_id);
          setIsLoading(false);
          setStartButtonVisible(!isStartButtonVisible);
        }
      } catch (error) {
        console.error("Error fetching the API:", error);
        setIsLoading(false);
      }
    } else {
      setFormData({
        ...formData,
        checkvaluemsg: !isStartButtonVisible
          ? "please type start for start."
          : "please type stop for stop.",
      });
      return false;
    }
  };

  //next
  const handleNextButtonClick = async () => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return;
    }

    setIsLoading(true);

    try {
      // Make the API call
      const response = await axios.post(
        `${BASE_URL}/doctor/patient/next`,
        { shift_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        allPatient(shift_id);
      } else {
        Swal.fire({
          icon: "error",
          title: "API Error",
          text: "Failed to fetch the next patient.",
        });
        console.error("API request failed with status:", response.status);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Network Error",
        text: "An error occurred while making the request. Please try again later.",
      });
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //skip
  const handleSkipButtonClick = async () => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    try {
      setIsLoading(true);
      const reqData = {
        token_number: isRecallToken,
        status: tokenType,
        shift_id: shift_id,
      };
      const response = await axios.post(
        `${BASE_URL}/doctor/patient/skip`,
        reqData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        allPatient(shift_id);
        setIsRecallToken(null);
        setIsRecallModel(false);
        setTokenType("panding");
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(true);
    }
  };

  //rollback
  const handleRollBackButtonClick = async (e) => {
    e.preventDefault();
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/doctor/patient/recall`,
        {
          token_number: isRecallToken,
          time: inputValue,
          status: tokenType,
          shift_id: shift_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        allPatient(shift_id);
        setIsRecallModel(false);
        setInputValue(null);
        setTokenType("panding");
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  //Skip-rollback
  const handleSkipRollBackButtonClick = async (e) => {
    e.preventDefault();
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/doctor/patient/skip-recall`,
        {
          token_number: isRecallToken,
          skip_number: inputValue,
          status: tokenType,
          shift_id: shift_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        setIsSkipRecallModel(false);
        setInputValue(null);
        setIsRecallToken(null);
        allPatient(shift_id);
        setTokenType("panding");
      } else {
        setIsRecallToken(null);
        setIsSkipRecallModel(false);
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBreakModel = () => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    setShowBreakModal(!showBreakModal);
  };
  const handleBreakButtonClick = async (e) => {
    e.preventDefault();
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    const breakUrl =
      inputValue != null
        ? `${BASE_URL}/doctor/start-break`
        : `${BASE_URL}/doctor/end-break`;

    setIsLoading(true);
    try {
      const response = await axios.post(
        breakUrl,
        { break_duration: inputValue, shift_id: shift_id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      if (response.status === 200) {
        allPatient(shift_id);
        if (inputValue) {
          setShowBreakModal(!showBreakModal);
        }
        setInputValue(null);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const allPatient = (shiftId) => {
    axios
      .post(
        `${BASE_URL}/doctor/patient/all`,
        { shift_id: shiftId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      )
      .then((response) => {
        const data = response.data.data;
        if (data.activeShift) {
          localStorage.setItem("shift_id", data.activeShift.id);
          setShiftId(data.activeShift.id);

          if (data.activeShift.id != shift_id) {
            window.location.reload();
          }
        }
        setFeeCount(data.feeCount);
        setTotalFee(data.totalFee);
        setPatients(data.patients);
        setSkipPatients(data.skips);
        setFinishPatients(data?.finished);
        setReplaceToken(data?.replaceToken);
        setTokenValue(data.current);
        setStartButtonVisible(data.start);
        setIsBreak(data.is_break);
        setTotal(data.total);
        setOnlineTokens(data.onlineTokens);
        setDescription(data.descriptions);
        setSeconds(data.break_countdown);
        /* setSubscriptionEnd(data.subscription_end);
        setSubscriptionDate(data.subscription_date);
        setIsSubscribe(data.is_subscribe);
        localStorage.setItem("subscription_date", data.subscription_date);
        localStorage.setItem("subscription_end", data.subscription_end);
        if (data.subscription_end == 0) {
          getPermission();
        } */
        setShift(data.shifts);
      })
      .catch((error) => {
        console.error("Error fetching data", error.response.data.message);
        if (error.response.data.message == "Unauthorized") {
          localStorage.removeItem(TOKEN_KEY);
          localStorage.removeItem(AUTH_KEY);
          localStorage.removeItem("role_permissions");
          localStorage.removeItem("menu");
          window.location.href = "../";
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      allPatient(shift_id);
    }, 5000); // Poll every 5 seconds

    return () => clearInterval(interval);
  }, []);

  /*   const echo = new Echo({
    broadcaster: "pusher",
    key: "18149ecf6ecc5e5d658b",
    cluster: "ap2",
    forceTLS: true,
  });

  useEffect(() => {
    echo.channel("data-changed").listen("DataChanged", (event) => {
      console.log("Data updated:", event.data);
      allPatient();
    });

    return () => echo.disconnect();
  }, []); */

  const getPermission = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/role/permissions`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      const permissions = response.data.data.permissions;
      const role_permissions = response.data.data.role_permissions;
      localStorage.setItem(
        "role_permissions",
        JSON.stringify(role_permissions)
      );
      localStorage.setItem("menu", JSON.stringify(permissions));
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const handleEmergency = async (e) => {
    e.preventDefault();
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    const data = {
      name: formData.name,
      dob: formData.dob,
      gender: formData.gender,
      mobile: formData.mobile,
      email: formData.email,
      shift_id: shift_id,
    };
    setIsLoading(true);
    try {
      axios
        .post(`${BASE_URL}/doctor/patient/emergency`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        })
        .then((response) => {
          //console.log("Token added successfully", response.data.message);
          Swal.fire({
            icon: "success",
            title: `Token Number: ${response.data.data.token_number}`,
            text: `Time - ${response.data.data.expected_time}`,
          });
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error adding token", error.response.data.message);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to add the token. Please try again.",
          });
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error fetching the API:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    allPatient(shift_id);
    if (userPer) {
      const startPermission = userPer.some((perm) => perm.id === 8);
      const stopPermission = userPer.some((perm) => perm.id === 9);
      const nextPermission = userPer.some((perm) => perm.id === 10);
      const skipPermission = userPer.some((perm) => perm.id === 11);
      const recallPermission = userPer.some((perm) => perm.id === 15);
      const startbreakPermission = userPer.some((perm) => perm.id === 12);
      const stopbreakPermission = userPer.some((perm) => perm.id === 11);
      const skippataintPermission = userPer.some((perm) => perm.id === 11);
      setPermissions({
        start: startPermission,
        stop: stopPermission,
        next: nextPermission,
        skip: skipPermission,
        recall: recallPermission,
        startbreak: startbreakPermission,
        stopbreak: stopbreakPermission,
        skipPatient: skippataintPermission,
      });
    }
  }, []);

  const modelToggle = () => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    const type = isModel ? false : true;
    setIsModel(type);
  };

  const recallModelToggle = (value) => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    setIsRecallToken(value);
    setIsRecallModel(!isRecallModel);
    setTokenType("recalled");
  };

  const tokenInModelToggle = (patient = null) => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    if (patientInModel === true) {
      allPatient(shift_id);
    }
    setPatientDetails(patient);
    setPatientInModel(!patientInModel);
    setIsSkipRecallModel(false);
  };

  const printOpenModel = () => {
    setPrintModal(false);
    setPatientDetails([]);
  };

  const skiprecallModelToggle = (value, type, patient) => {
    if (shift_id.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select your shift.",
      });
      return false;
    }
    const data = isSkipRecallModel ? false : true;
    setPatientDetails(patient);
    setIsRecallToken(value);
    setTokenType(type);
    setIsSkipRecallModel(data);
  };

  const emergencyModelToggle = () => {
    const type = isEmergencyModel ? false : true;
    setIsEmergencyModel(type);
  };

  const handleDescriptionSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/doctor/patient/description`,
        { description: inputValue },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      if (response.status === 200) {
        allPatient(shift_id);
        setInputValue(null);
        modelToggle();
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const editToken = (token_id) => {
    navigate(`../admin/token/edit/${token_id}`);
  };

  const shiftChange = (e) => {
    setFormData({
      ...formData,
      ["shift_id"]: e.target.value,
    });
    allPatient(e.target.value);
  };

  return (
    <>
      <div className="dashboard">
        <div
          className="d-md-none"
          style={{
            width: "100%",
            backgroundColor: "black",
            color: "wheat",
            marginBottom: "60px",
          }}
        >
          <Header />
        </div>
        <div className="d-flex">
          <Dashboard />
          <div className="w-100">
            <div className="main-container">
              <hr className="d-block d-lg-none" />
              <Head />
              <div className="w-100">
                <div className="pb-2 px-3">
                  Welcome <b>{USER_DETAILS.name}</b>
                </div>
                {is_break ? (
                  <>
                    <DashboardBreak
                      seconds={seconds}
                      setSeconds={setSeconds}
                      handleBreakModel={handleBreakModel}
                      isLoading={isLoading}
                      permission={permissions.stopbreak}
                      handleBreakButtonClick={handleBreakButtonClick}
                    />
                    <div className="row justify-content-center mt-2">
                      <div className="col-md-11 text-center">
                        <TokenListDash
                          isLoading={isLoading}
                          patients={patients}
                          editToken={editToken}
                          tokenInModelToggle={tokenInModelToggle}
                        />
                        {skipPatients.length > 0 && (
                          <SkipPatientDash
                            isLoading={isLoading}
                            permissions={permissions}
                            skipPatients={skipPatients}
                            skiprecallModelToggle={skiprecallModelToggle}
                            tokenInModelToggle={tokenInModelToggle}
                            editToken={editToken}
                          />
                        )}
                        {finishedPatients.length > 0 && (
                          <DonePatientList
                            finishedPatients={finishedPatients}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="container">
                    {/* {subscriptionEnd <= 8 && (
                      <div className="alert alert-danger d-flex flex-wrap">
                        {subscriptionEnd <= 8 && subscriptionEnd >= 1 ? (
                          <h6 className="text-danger mb-0">
                            Your {!isSubscribe && "demo"} subscription will
                            expire on {subscriptionDate}.
                          </h6>
                        ) : (
                          <h6 className="text-danger mb-0">
                            Your {!isSubscribe && "demo"} subscription has
                            expired on {subscriptionDate}.{" "}
                            {!isSubscribe &&
                              "& You don't have subscription. Please subscribe to continue."}
                          </h6>
                        )}
                        <button
                          className="btn btn-primary py-0 ms-lg-2 mb-0"
                          onClick={() => navigate(`/admin/wallet`)}
                        >
                          Renew it
                        </button>
                      </div>
                    )} */}
                    <div className="mb-0">
                      <DashboardTotal
                        total={total}
                        pending={patients.length}
                        skip={skipPatients.length}
                        fees={totalFee}
                        feeCount={feeCount}
                        online={onlineTokens}
                        replace={replaceToken}
                      />
                    </div>
                    <div>
                      <div className="row">
                        {shifts.length > 1 && (
                          <div className="col-md-3 mb-2">
                            <ShiftComponent
                              shift_id={formData.shift_id}
                              shiftChange={shiftChange}
                              shifts={shifts}
                              status={isStartButtonVisible}
                            />
                          </div>
                        )}
                        <div className="col-md-3 mb-2">
                          {isStartButtonVisible ? (
                            permissions.start > 0 && (
                              <button
                                className="btn btn-danger px-4 mb-3 form-control"
                                type="button"
                                onClick={() => startOpenModel(0)}
                                disabled={isLoading}
                              >
                                <span className="fs-5 fw-bold align-items-center">
                                  {isLoading && (
                                    <span className="spinner-border spinner-border-sm mr-1"></span>
                                  )}
                                  <AiOutlineStop className="" /> Stop
                                </span>
                              </button>
                            )
                          ) : (
                            <>
                              {permissions.stop > 0 && (
                                <button
                                  className="btn btn-success px-4 mb-3 form-control"
                                  type="button"
                                  onClick={() => startOpenModel(1)}
                                  disabled={isLoading}
                                >
                                  <span className="fs-5 fw-bold align-items-center">
                                    {isLoading && (
                                      <span className="spinner-border spinner-border-sm mr-1"></span>
                                    )}
                                    <VscDebugStart className="" /> Start
                                  </span>
                                </button>
                              )}
                            </>
                          )}
                        </div>
                        {!isStartButtonVisible && (
                          <div className="col mb-2">
                            <TokenListDash
                              isLoading={isLoading}
                              patients={patients}
                              editToken={editToken}
                              tokenInModelToggle={tokenInModelToggle}
                            />
                            {finishedPatients.length > 0 && (
                              <DonePatientList
                                finishedPatients={finishedPatients}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {isStartButtonVisible && (
                      <div className="row">
                        <div className="col-md-12 col-lg-7 col-sm-12">
                          <div className="card mb-4">
                            <div
                              className="card-header"
                              style={{ backgroundColor: "#96C8A2" }}
                            >
                              <h5 className="fw-bold text-center">
                                Current Token
                              </h5>
                            </div>
                            <div
                              className="card-body "
                              style={{ backgroundColor: "#E0EEEE" }}
                            >
                              {/* =================================== desktop View ================================= */}

                              <div className="d-none d-lg-block d-md-block">
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                  {!is_break && (
                                    <div className="">
                                      {permissions.next > 0 && (
                                        <NextButton
                                          handleNext={handleNextButtonClick}
                                          isLoading={isLoading}
                                        />
                                      )}
                                      <br />
                                      {permissions.skip > 0 && (
                                        <SkipButton
                                          handleSkip={handleSkipButtonClick}
                                          isLoading={isLoading}
                                          setIsRecallToken={setIsRecallToken}
                                        />
                                      )}
                                    </div>
                                  )}
                                  <div className="token-view">
                                    <div
                                      className="text-center pb-3 px-3"
                                      style={{
                                        backgroundColor: "grey",
                                        borderRadius: "50px",
                                      }}
                                    >
                                      {!is_break ? (
                                        <div>
                                          <span
                                            className="text-center"
                                            style={{ fontSize: "2.5rem" }}
                                          >
                                            {tokenValue?.token_number}
                                          </span>
                                          <h4 className="fs-lg-4 h4 py-0 my-0">
                                            {tokenValue?.patient?.name ||
                                              "No Name"}
                                          </h4>
                                          <small>
                                            {tokenValue?.patient?.email}
                                          </small>
                                        </div>
                                      ) : (
                                        <span style={{ fontSize: "2rem" }}>
                                          It's Break
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="token-button d-flex flex-column">
                                    {!is_break && (
                                      <>
                                        {permissions.recall > 0 && (
                                          <RecallButton
                                            modelToggle={recallModelToggle}
                                            isLoading={isLoading}
                                            token_number={
                                              tokenValue?.token_number
                                            }
                                          />
                                        )}
                                      </>
                                    )}
                                    {permissions.skip > 0 && (
                                      <button
                                        className="btn btn-danger fs-5 fw-bold"
                                        type="button"
                                        onClick={handleBreakModel}
                                        disabled={isLoading}
                                        style={{ width: "160px" }}
                                      >
                                        <span className="fs-5 fw-bold">
                                          <MdOutlineNotes /> Start Break
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* =================================== end desktop View ================================= */}

                              {/* =================================== mobile View ================================= */}
                              <div className="d-block d-lg-none d-md-none">
                                <div className="">
                                  <div className="d-flex justify-content-between">
                                    {is_break ? (
                                      <>
                                        <button
                                          className="btn btn-danger fs-2 fw-bold mb-4 mt-2"
                                          type="button"
                                          onClick={handleBreakModel}
                                          disabled={isLoading}
                                        >
                                          <span className="fs-5 fw-bold">
                                            <MdOutlineNotes /> Add more time
                                          </span>
                                        </button>
                                        <button
                                          className="btn btn-success fw-bold"
                                          type="button"
                                          onClick={handleBreakButtonClick}
                                          disabled={isLoading}
                                        >
                                          <span className="fs-5 fw-bold">
                                            {isLoading && (
                                              <span className="spinner-border spinner-border-sm mr-1"></span>
                                            )}
                                            <MdOutlineNotes />
                                            Stop Break
                                          </span>
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        className="btn btn-danger mb-4 mt-2 fs-6"
                                        type="button"
                                        onClick={handleBreakModel}
                                        disabled={isLoading}
                                        style={{ width: "160px" }}
                                      >
                                        <span className="fw-bold">
                                          <MdOutlineNotes /> Start Break
                                        </span>
                                      </button>
                                    )}
                                    {!is_break && (
                                      <>
                                        <button
                                          className="btn btn-info mb-4 mt-2 fs-5 fw-bold px-lg-5"
                                          type="button"
                                          onClick={() =>
                                            recallModelToggle(
                                              tokenValue?.token_number
                                            )
                                          }
                                          disabled={isLoading}
                                          style={{ width: "160px" }}
                                        >
                                          <span className="fs-5 fw-bold">
                                            {isLoading && (
                                              <span className="spinner-border spinner-border-sm mr-1"></span>
                                            )}
                                            <IoMdRefresh />
                                            Recall
                                          </span>
                                        </button>
                                      </>
                                    )}
                                  </div>

                                  <div className="token-view d-flex justify-content-center">
                                    <div
                                      className="px-lg-3 px-3 py-2 mb-3"
                                      style={{
                                        backgroundColor: "grey",
                                        borderRadius: "25px",
                                        width: "250px",
                                      }}
                                    >
                                      {!is_break ? (
                                        <span className="d-flex align-items-center">
                                          <span
                                            className="text-center"
                                            style={{
                                              fontSize: "2.5rem",
                                              width: "60px",
                                              fontWeight: 600,
                                            }}
                                          >
                                            {tokenValue?.token_number}
                                          </span>
                                          <div>
                                            <h4 className="fs-lg-4 fs-5">
                                              {tokenValue?.patient?.name}
                                            </h4>
                                            <p className="mb-0 pb-0">
                                              {tokenValue?.patient?.email}
                                            </p>
                                          </div>
                                        </span>
                                      ) : (
                                        <span style={{ fontSize: "2rem" }}>
                                          It's Break
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    {!is_break && (
                                      <SkipButton
                                        handleSkip={handleSkipButtonClick}
                                        isLoading={isLoading}
                                        setIsRecallToken={setIsRecallToken}
                                      />
                                    )}
                                    {!is_break && (
                                      <button
                                        className="btn btn-primary mb-4 mt-2 px-4"
                                        type="button"
                                        onClick={handleNextButtonClick}
                                        disabled={isLoading}
                                        style={{ width: "160px" }}
                                      >
                                        <span className="fs-6 fw-bold">
                                          {isLoading && (
                                            <span className="spinner-border spinner-border-sm mr-1"></span>
                                          )}
                                          <BiSkipNext /> Next
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* =========================== end mobile view */}
                            </div>
                          </div>
                          <div className="card mb-4">
                            <div
                              className="card-header d-flex justify-content-between"
                              style={{ backgroundColor: "#96C8A2" }}
                            >
                              <h5 className="fw-bold text-center">
                                Description
                              </h5>
                              <AiOutlinePlusCircle
                                className="fw-bold fs-3"
                                onClick={modelToggle}
                                role="button"
                              />
                            </div>
                            <div className="card-body">
                              <div
                                className="card-title text-center"
                                style={{ backgroundColor: "#96C8A2" }}
                              ></div>
                              {descriptions.map((item, index) => (
                                <p key={index}>
                                  {index + 1} - {item.message}
                                </p>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12">
                          <div className="card mb-4">
                            <div
                              className="card-header d-flex justify-content-between"
                              style={{ backgroundColor: "#96C8A2" }}
                            >
                              <h5 className="fw-bold text-center">
                                Patients List
                              </h5>
                            </div>
                            <div
                              className="card-body pe-0 ps-0"
                              style={{ backgroundColor: "#E0EEEE" }}
                            >
                              {isLoading ? (
                                <p>Loading...</p>
                              ) : (
                                <div
                                  className="w-100"
                                  style={{ height: "250px", overflowY: "auto" }}
                                >
                                  <div className="d-flex flex-wrap ps-2">
                                    {patients.map((patient, index) => (
                                      <Token
                                        key={index}
                                        patient={patient}
                                        recallModelToggle={
                                          skiprecallModelToggle
                                        }
                                        editToken={editToken}
                                        tokenInModelToggle={tokenInModelToggle}
                                        type="pending"
                                        status="emergency"
                                      />
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {skipPatients.length > 0 && (
                            <SkipPatientDash
                              isLoading={isLoading}
                              permissions={permissions}
                              skipPatients={skipPatients}
                              skiprecallModelToggle={skiprecallModelToggle}
                              tokenInModelToggle={tokenInModelToggle}
                              editToken={editToken}
                            />
                          )}
                          {finishedPatients.length > 0 && (
                            <DonePatientList
                              finishedPatients={finishedPatients}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModel === true && (
        <DescriptionModel
          show={isModel}
          handleSubmit={handleDescriptionSubmit}
          inputValue={inputValue}
          setInputValue={setInputValue}
          modelToggle={modelToggle}
          isLoading={isLoading}
        />
      )}
      {isRecallModel === true && (
        <RecallModel
          show={isRecallModel}
          handleSubmit={handleRollBackButtonClick}
          inputValue={inputValue}
          setInputValue={setInputValue}
          modelToggle={recallModelToggle}
          isLoading={isLoading}
        />
      )}
      {patientInModel === true && (
        <PatientInModel
          show={patientInModel}
          patient={patientDetails}
          setPrintModal={setPrintModal}
          setPatientInModel={setPatientInModel}
          isLoading={isLoading}
          allPatient={allPatient}
          shift_id={formData.shift_id}
          setPatientDetails={setPatientDetails}
        />
      )}
      {showBreakModal === true && (
        <BreakModel
          show={showBreakModal}
          handleSubmit={handleBreakButtonClick}
          inputValue={inputValue}
          setInputValue={setInputValue}
          modelToggle={handleBreakModel}
          isLoading={isLoading}
        />
      )}
      {isSkipRecallModel === true && (
        <SkipRecallModel
          show={isSkipRecallModel}
          handleSubmit={handleSkipRollBackButtonClick}
          inputValue={inputValue}
          handleSkipSubmit={handleSkipButtonClick}
          setInputValue={setInputValue}
          modelToggle={skiprecallModelToggle}
          isLoading={isLoading}
          tokenType={tokenType}
          tokenInModelToggle={tokenInModelToggle}
          patient={patientDetails}
        />
      )}
      {isEmergencyModel === true && (
        <EmergencyModel
          show={isEmergencyModel}
          handleSubmit={handleEmergency}
          formData={formData}
          handleInputChange={handleInputChange}
          modelToggle={emergencyModelToggle}
          isLoading={isLoading}
        />
      )}
      {startModel === true && (
        <StartModel
          show={startModel}
          handleSubmit={handleToggleStartButton}
          modelToggle={startOpenModel}
          isStartButtonVisible={isStartButtonVisible}
          isLoading={isLoading}
          formData={formData}
        />
      )}

      {printModal === true && (
        <PrintModel
          show={printModal}
          modelToggle={printOpenModel}
          patient={patientDetails}
        />
      )}
    </>
  );
};
