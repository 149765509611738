import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import authService from "../services/authService";
import { BiSolidMap, BiStar } from "react-icons/bi";
import TokenCard from "./TokenCard";
import TokenCreateCard from "./TokenCreateCard";
import axios from "axios";
import { BASE_URL, USER_DETAILS } from "../utils";
import { Divider } from "antd";
import Swal from "sweetalert2";

const DoctorProfile = () => {
  const { doctorName, doctorId } = useParams();
  const params = new URLSearchParams(window.location.search);
  const status = params.get("status");
  const message = params.get("message");
  const token = params.get("token");

  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [tokenData, setTokenData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [shiftId, setShiftId] = useState(false);

  const handelmodalcontain = () => {
    setIsTrue(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleBookNowClick = (shift_id) => {
    setShiftId(shift_id);
    setIsTrue(true);
    setShowModal(true);
  };
  const getData = async () => {
    setIsLoading(true);
    const reqeustParam = { doctorId: doctorId };
    const resp = await authService.getDoctorDetails(reqeustParam);
    const resData = await resp.json();
    if (resData.success) {
      setDetails(resData.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getData();
    if (token) {
      Swal.fire({
        icon: "success",
        title: `Token - ${token}`,
        text: `Your token number is ${token}`,
      });
    }
    if (status == "error") {
      Swal.fire({
        icon: "error",
        title: `Payment field `,
        text: `${message}`,
      });
    }
  }, []);

  return (
    <div className="py-2">
      <Container>
        {USER_DETAILS && (
          <div className="text-end mb-1">
            <Link
              className="btn btn-warning py-0"
              to={`${window.location.origin.toString()}/admin/dashboard`}
            >
              Dashboard
            </Link>
          </div>
        )}
        {!isLoading ? (
          <>
            {details.name ? (
              <Card className="px-4 shadow-sm">
                <Row>
                  <Col xs={12} md={12} lg={4} className="px-0 d-lg-none">
                    <div
                      className="px-2 mb-3"
                      style={{ borderLeft: "1px solid #cfc4c4" }}
                    >
                      <TokenCard doctor={details} setTokenData={setTokenData} />
                    </div>
                    {tokenData.patient && (
                      <div>
                        <TokenDetail tokenData={tokenData} />
                      </div>
                    )}
                  </Col>
                  <Col xs={12} md={12} lg={8} className="px-0">
                    <Row>
                      <Col xs={12} md={3} lg={3} className="text-center">
                        <img
                          src={details.profile || "../../doct.png"}
                          alt="doctor profile"
                          className="ps-2 mb-4"
                          style={{
                            width: "150px",
                            borderRadius: "10%",
                            border: "0px solid #00a19a",
                          }}
                        />
                      </Col>
                      <Col xs={12} md={9} lg={9}>
                        <div className="pe-2">
                          <h5 style={{ color: "#00a19a" }}>
                            {details?.name || doctorName}
                          </h5>
                          <div className="mt-2" style={{ color: "#555" }}>
                            {details?.location?.category?.name}
                          </div>
                          <div className="mt-2" style={{ color: "#555" }}>
                            <strong>Contact - </strong>{" "}
                            {details?.location?.ofc_contact_no_1}
                          </div>
                          <div className="mt-2" style={{ color: "#555" }}>
                            <strong>About - </strong> {details?.location?.about}
                          </div>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="py-3">
                          <h5>Specialization :-</h5>
                          <hr className="my-0 pb-2" />
                          <div className="row">
                            {details.specialists.map((field, index) => (
                              <div className="col-md-6" key={index}>
                                <p className="mb-1">
                                  <BiStar /> {field}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                        {tokenData.patient && (
                          <div className="px-2 d-none d-lg-block mt-4">
                            <TokenDetail tokenData={tokenData} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={9} lg={4} className="px-0 d-none d-lg-block">
                    <div
                      className="px-2"
                      style={{ borderLeft: "1px solid #cfc4c4" }}
                    >
                      <TokenCard doctor={details} setTokenData={setTokenData} />
                    </div>
                  </Col>
                </Row>
                {/* Table for Shifts, Time, Fee, and Location */}
                <h4 id="targetId" className="my-3" style={{ color: "#00a19a" }}>
                  Working Hours
                </h4>
                <Row>
                  {details.shifts &&
                    details.shifts.map((item, i) => (
                      <Col
                        key={item.id}
                        xs={12}
                        md={4}
                        lg={4}
                        className="px-0 mb-3"
                      >
                        <Card
                          className="me-lg-2"
                          style={{
                            border:
                              i == 0
                                ? "2px solid #fda600"
                                : "2px solid lightblue",
                          }}
                        >
                          <p
                            className="text-center h4 py-1"
                            style={{
                              background: i == 0 ? " #fda600" : "lightblue",
                              color: i == 0 ? "white" : "black",
                              borderBottom:
                                i == 0
                                  ? "1px solid #fda600"
                                  : "1px solid lightblue",
                            }}
                          >
                            {item?.name}
                          </p>
                          <div className="px-2">
                            <p className="mb-2 h5" style={{ color: "#00a19a" }}>
                              Timing - &nbsp; {item?.start_ti} &nbsp;To&nbsp;{" "}
                              {item?.end_ti}
                            </p>
                            <p className="my-2 h6" style={{ color: "#00a19a" }}>
                              Fee - ₹{item?.fees}
                            </p>
                            <p className="mb-2 h6" style={{ color: "#00a19a" }}>
                              Address - {item?.address}
                            </p>
                            <p>
                              <a
                                href={item?.map_location}
                                target="_blank"
                                className="text-decoration-none text-black h6 mb-1 pb-0"
                              >
                                See on google map <BiSolidMap />
                              </a>
                            </p>
                          </div>
                          <div className="text-end p-1">
                            {details.online_booking == 1 && (
                              <Button
                                onClick={() => handleBookNowClick(item.id)}
                                className="btn-sm btn-warning m-0 py-1 w-100 text-black"
                              >
                                <b className="blink">नंबर लगाएं</b>
                              </Button>
                            )}
                          </div>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </Card>
            ) : (
              <div className="text-center" style={{ paddingTop: "15%" }}>
                <img src="../../404.webp" alt="doctor profile" className="" />
                <h2 className="text-danger mt-4">Doctor not found . . .</h2>
              </div>
            )}
          </>
        ) : (
          <div className="text-center" style={{ paddingTop: "20%" }}>
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        <TokenCreateCard
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          selectedDoctor={details}
          isTrue={isTrue}
          handelmodalcontain={handelmodalcontain}
          shiftId={shiftId}
        />
      </Container>
    </div>
  );
};

const TokenDetail = ({ tokenData }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [show, setShow] = useState(false);
  const [tokenReplaceId, setTokenReplaceId] = useState(false);

  const handleShow = (e) => {
    e.preventDefault();
    const token_replace_id = e.target.token_replace_id.value;
    if (token_replace_id != "") {
      setTokenReplaceId(token_replace_id);
      setShow(true);
    } else {
      setMessage("Please select a token to replace.");
    }
  };
  const handleClose = () => setShow(false);

  const handleConfirm = async () => {
    setLoading(true);
    setMessage(null);
    const reqeustParam = {
      doctor_id: tokenData.doctor_id,
      token_id: tokenData.id,
      token_replace_id: tokenReplaceId,
    };
    const url = `${BASE_URL}/replace-token`;
    axios
      .post(url, reqeustParam, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data && response.data.data) {
          window.location.href = response.data.data;
        }
      })
      .catch((error) => {
        if (error.response) {
          setMessage(error.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
        setShow(false);
      });
  };

  const cancelToken = (token_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel your appointment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        authService
          .cancelToken({ token_id: token_id })
          .then((res) => res.json())
          .then((data) => {
            
          })
          .catch((err) => {
            console.log("Error fetching the API:", err);
          });
      }
    });
  };
  return (
    <>
      <div className="w-100 pb-2 ">
        <hr />
        {tokenData.tokens.length > 0 && tokenData.patient_in == 1 && (
          <div
            className="p-3 text-danger mb-3"
            style={{ border: "2px solid red" }}
          >
            <h4> टोकन नंबर बदलें </h4>
            <form onSubmit={handleShow}>
              <div className="row">
                <div className="col-lg-6 mb-3">
                  <select
                    name="token_replace_id"
                    className="form-control"
                    required
                  >
                    <option value="">Select Token</option>
                    {tokenData.tokens &&
                      tokenData.tokens.map((item, index) => (
                        <option key={index} value={item.id}>
                          Token-{item.token_number}, Time-{item.expected_time}
                        </option>
                      ))}
                  </select>
                  <p className="text-danger">{message}</p>
                </div>
                <div className="col-lg-6">
                  <button
                    className="btn btn-primary py-1"
                    type="submit"
                    disabled={loading}
                  >
                    {loading
                      ? "Loading..."
                      : "₹" + tokenData.replace_free + " Pay Now"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        <h4>TOKEN DETAILS</h4>
        <div>
          <h6 className="text-danger">
            Note :- Please note that the appointment time may vary slightly
            based on the Doctor's availability and patient flow. We will do our
            best to keep you updated and minimize any wait time.
          </h6>
        </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Patient Name</th>
              <th>{tokenData?.patient?.name}</th>
            </tr>
            <tr>
              <th>Token Number</th>
              <th>{tokenData?.token_number}</th>
            </tr>
            <tr>
              <th>Token Status</th>
              <th>
                {tokenData?.is_type == "Skip" ? "Skipped" : tokenData?.is_type}
              </th>
            </tr>
            <tr>
              <th>Expected Time</th>
              <th>{tokenData?.expected_time}</th>
            </tr>
            <tr>
              <th>Left Time</th>
              <th>{tokenData?.left_time}</th>
            </tr>
            <tr>
              <th>No. of Patient (before you)</th>
              <th>{tokenData?.patientTotal}</th>
            </tr>
            <tr>
              <th>Arrived at Clinic</th>
              <th>{tokenData?.patientIn}</th>
            </tr>
            <tr>
              <td colSpan={2} className="text-orange">
                If you want to cancel your token, please click the "Cancel
                Token" button.
                <button
                  className="btn btn-danger py-0 ms-3"
                  onClick={() => cancelToken(tokenData.id)}
                >
                  Cancel Token
                </button>
              </td>
            </tr>
          </thead>
        </table>
        <h5 className="text-danger text-center">समय में बदलाव संभव है।</h5>
        <hr />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          Are you sure you want to replace the token?
          <Divider />
          <h6 className="text-danger">
            Note-: कृपया भुगतान करने के बाद कुछ देर के लिए इंतजार करे तत्काल कोई
            बटन न दबाए।
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" disabled={loading} onClick={handleConfirm}>
            {loading ? "Loading..." : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DoctorProfile;
