import React, { useEffect, useState } from "react";
import { Dashboard } from "./Dashboard";
import axios from "axios"; // Import Axios
import { AUTH_TOKEN, BASE_URL } from "../../utils";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import Head from "./components/Head";

export const EditToken = () => {
  const navigate = useNavigate();
  const { token_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    token_id: token_id,
    shift_id: "",
    name: "",
    dob: "",
    gender: "Male",
    mobile: "",
    email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const editPatient = async () => {
    try {
      // Make the API call
      setIsLoading(true);
      const reqData = { token_id: token_id };
      const response = await axios.post(
        `${BASE_URL}/doctor/patient/edit`,
        reqData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        const res = response.data.data;
        const data = {
          token_id: res.id,
          name: res?.patient?.name,
          dob: res?.patient?.dob,
          gender: res?.patient?.gender || "Male",
          mobile: res?.patient?.mobile,
          email: res?.patient?.email,
          token_number: res?.token_number,
          today: res.today,
          shift_id: res.shift_id,
        };
        setFormData(data);
        setIsLoading(false);
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(`${BASE_URL}/doctor/patient/update`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      })
      .then((response) => {
        //console.log("Patient updated successfully", response.data.message);
        Swal.fire({
          icon: "success",
          title: "Patient updated successfully",
          text: `Token Number - ${response.data.data.token}`,
        });
        setIsLoading(false);
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error adding token", error.response.data.message);
        // Use SweetAlert to display an error message
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    editPatient();
  }, []);

  return (
    <div className="dashboard">
      <div
        className="d-md-none"
        style={{
          width: "100%",
          backgroundColor: "black",
          color: "wheat",
          marginBottom: "80px",
        }}
      >
        <Header />
      </div>
      <div className="d-flex">
        <Dashboard />
        <div className="w-100">
          <Head />
          <div className="main-container my-3">
            <div className="d-flex justify-content-center">
              <div className="col-md-8 col-lg-6 col-sm-12">
                {formData && (
                  <h6 className="bg-website p-3 my-0">
                    Edit Token :- {formData?.token_number}, {formData?.name}
                  </h6>
                )}
                <div className="card card-body" style={{ borderRadius: 0 }}>
                  <h4 className="">Edits Token</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      {/* <div className="col-md-6 my-4">
                        <label className="mb-2">Shift Time</label>
                        <select
                          className="form-control"
                          placeholder="Enter Name"
                          onChange={(e) => handleInputChange(e)}
                          name="shift_id"
                          value={formData.shift_id}
                        >
                          {shifts.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6 my-4">
                        <label className="mb-2">Date</label>
                        <input
                          className="form-control"
                          onChange={(e) => handleInputChange(e)}
                          name="date"
                          type="date"
                          value={formData.today}
                          placeholder="select date"
                          min={today}
                          pattern="dd-mm-yyyy"
                        />
                      </div> */}

                      <div className=" col-md-6 mb-3">
                        <label className="mb-2">Mobile</label>
                        <input
                          type="text"
                          inputmode="numeric"
                          className="form-control"
                          placeholder="Enter Mobile"
                          onChange={(e) => handleInputChange(e)}
                          name="mobile"
                          maxLength={10}
                          onKeyPress={(e) =>
                            !/[0-9]/.test(e.key) && e.preventDefault()
                          }
                          value={formData.mobile}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="mb-2">Name</label>
                        <input
                          className="form-control"
                          placeholder="Enter Name"
                          onChange={(e) => handleInputChange(e)}
                          name="name"
                          value={formData.name}
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="mb-2">Age</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Age"
                          onChange={(e) => handleInputChange(e)}
                          name="dob"
                          value={formData.dob}
                          maxLength={2}
                          onKeyPress={(e) =>
                            !/[0-9]/.test(e.key) && e.preventDefault()
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="mb-2">Gender</label>
                        <select
                          className="form-control"
                          onChange={(e) => handleInputChange(e)}
                          name="gender"
                          value={formData.gender}
                        >
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>

                      <div className="col-md-12 mb-3">
                        <label className="mb-2">Address</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Address"
                          onChange={(e) => handleInputChange(e)}
                          name="email"
                          value={formData.email}
                        />
                      </div>
                      <div className="col-md-12 mb-3 text-end">
                        <button
                          disabled={isLoading}
                          className="btn btn-primary ms-2"
                          type="submit"
                        >
                          {isLoading && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
