import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal } from "react-bootstrap";
import { AUTH_TOKEN, BASE_URL } from "../../../utils"; 

export const StaffModel = ({
  show,
  handleSubmit,
  handleInputChange,
  formData,
  modelToggle,
  isLoading,
  mode,
}) => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/role/list`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        }
      );
      setRoles(response.data.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  return (
    <div>
      <Modal className="modal" show={show} onHide={modelToggle}>
        <Modal.Header closeButton>
          <Modal.Title className="h6">{mode} Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row px-6">
            <div className="col-md-12 mb-3">
                <label className="mb-2">Role</label>
                <select
                  className="form-control"
                  onChange={(e) => handleInputChange(e)}
                  name="role_id"
                  value={formData.role_id}
                  required
                >
                  <option>Select a role</option>
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-12 mb-3">
                <label className="mb-2">Name</label>
                <input
                  className="form-control"
                  placeholder="Enter a Staff Name"
                  onChange={(e) => handleInputChange(e)}
                  name="name"
                  value={formData.name}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="mb-2">Mobile</label>
                <input
                  type="text"
                  inputmode="numeric"
                  className="form-control"
                  placeholder="Enter Staff Mobile Number"
                  onChange={(e) => handleInputChange(e)}
                  name="mobile"
                  maxLength={10}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  value={formData.mobile}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="mb-2">Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter a Staff Email"
                  onChange={(e) => handleInputChange(e)}
                  name="email"
                  value={formData.email}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="mb-2">Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Please enter a Password"
                  onChange={(e) => handleInputChange(e)}
                  name="password"
                  required={!formData.id}
                />
              </div>
             
              <div className="col-md-12 mb-3 text-end">
                <button
                  disabled={isLoading}
                  className="btn btn-primary ms-2"
                  type="submit"
                >
                  {isLoading && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Submit
                </button>
                <Button className="ms-3" variant="secondary" onClick={modelToggle}>
                  Close
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
