import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { AUTH_TOKEN, BASE_URL } from "../utils";
import { BiStar } from "react-icons/bi";

const Feedback = ({ doctor_id = null, patientData = null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRating, setIsRating] = useState(0);
  const [formData, setFormData] = useState({
    doctor_id: doctor_id,
    name: patientData?.name || "",
    mobile: patientData?.mobile || "",
    email: "",
    review: "",
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      name: patientData?.name || "",
      mobile: patientData?.mobile || "",
    }));
  }, [patientData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isRating === 0) {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please select a rating.",
      });
      return;
    }

    const data = {
      ...formData,
      rating: isRating,
    };

    setIsLoading(true);
    axios
      .post(`${BASE_URL}/add-review`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Review & Rating",
          text: "Review saved successfully.",
        });
        setFormData({
          ...formData,
          review: "",
        });
        setIsRating(0);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to add review. Please try again.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRatingClick = (rating) => {
    setIsRating(rating);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleSearch = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  // const filteredFeedbacks = feedbacks.filter(
  //   (feedback) =>
  //     feedback.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     feedback.mobile.includes(searchQuery)
  // );

  return (
    <div className="card"  style={{ borderRadius: "29px", }}>
      <form className="px-4 py-4 w-100" onSubmit={handleSubmit}>
        {/* <input
          type="text"
          className="form-control mb-3"
          placeholder="Search by name or mobile number"
          value={searchQuery}
          onChange={handleSearch}
        />
      
        {filteredFeedbacks.map((feedback, index) => (
          <div key={index}>
            <p>{feedback.name}</p>
            <p>{feedback.mobile}</p>
            <p>{feedback.review}</p>
          </div>
        ))} */}

        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="mb-2">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="mb-2">Mobile</label>
            <input
              type="text"
              inputmode="numeric"
              className="form-control"
              placeholder="Enter Mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              maxLength={10}
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="mb-2">Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-6 mb-3">
            <label className="mb-2">Rating</label>
            <div>
              {[1, 2, 3, 4, 5].map((value) => (
                <BiStar
                  key={value}
                  size={30}
                  color={isRating >= value ? "orange" : "black"}
                  onClick={() => handleRatingClick(value)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <label className="mb-2">Review</label>
          <textarea
            className="form-control"
            placeholder="Enter Review"
            name="review"
            value={formData.review}
            onChange={handleInputChange}
            required
            style={{ height: "90px" }}
          />
        </div>
        <div className="col-md-12 mb-3 text-end">
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-primary ms-2 px-5"
          >
            {isLoading && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Feedback;
